<template>
  <div style="overflow: hidden; height: 100%;">
    <v-container class="pb-15 page-content" v-if="profileType == 'Admin'">
      <div style="display: flex; flex-drection: row; align-items: center;">
        <v-btn icon @click="onBack"
          ><v-icon v-text="'mdi-chevron-left'"
        /></v-btn>
        <div class="agency-title">Edit Agency</div>
        <div class="title-line ml-3" />
      </div>
      <v-row class="mt-3" v-if="agency">
        <v-col
          style="display: flex; flex-direction: column; border-right: 1px solid lightgray;"
          cols="12"
          md="6"
          class="px-15"
        >
          <span class="subtitle mb-6">Basic Information</span>
          <div>
            <v-row class="mt-3">
              <v-col cols="12" class="d-flex flex-row">
                <agency-avatar
                  :img="agency.image"
                  :size="240"
                  :name="agency.name"
                  :editable="true"
                  :onImageSelected="onImageSelected"
                />
                <div class="d-flex flex-column pl-5">
                  <span
                    style="font-size: 24px; color: #472583; font-weight: 500;"
                    v-text="'Upload your logo'"
                  />
                  <span>Image should be at least 540px by 540px</span>
                </div>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Agency Name"
                  dense
                  rounded
                  filled
                  v-model="agency.name"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Customize link"
                  dense
                  rounded
                  filled
                  v-model="agency.link"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details
                  label="Hours of Operation"
                  dense
                  rounded
                  filled
                  rows="3"
                  auto-grow
                  v-model="agency.hours_of_operation"
                  background-color="white"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="agency.causes"
                  :items="causes"
                  item-text="name"
                  item-label="name"
                  label="Causes"
                  multiple
                  clearable
                  auto-select-first
                  deletable-chips
                  chips
                  rounded
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Agency Video"
                  dense
                  rounded
                  filled
                  v-model="agency.video"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="EIN"
                  dense
                  rounded
                  filled
                  v-model="agency.ein"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <span class="subtitle mb-6 ma-3">Location</span>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Address"
                  type="address"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.address"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Address 2"
                  type="address"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.address2"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="City"
                  type="address"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.city"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  hide-details
                  label="State"
                  :items="stateOptions"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.state"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  hide-details
                  label="Zip Code"
                  type="zip"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.zip"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details
                  label="Additional location information"
                  type="text"
                  :row="3"
                  auto-grow
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.additional_location_information"
                />
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="updateAgency"
                >
                  Update Information
                </v-btn>
              </v-col>

              <v-col cols="12">
                <span class="subtitle mb-6">description</span>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details
                  label="Who we are"
                  dense
                  rounded
                  filled
                  rows="3"
                  auto-grow
                  v-model="agency.who_we_are"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  hide-details
                  label="What we do"
                  dense
                  rounded
                  filled
                  rows="3"
                  auto-grow
                  v-model="agency.what_we_do"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="updateAgency"
                >
                  Update Information
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col
          style="display: flex; flex-direction: column;"
          cols="12"
          md="6"
          class="px-15"
        >
          <div>
            <v-row>
              <span class="subtitle mb-6 ma-3">Contact Information</span>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Email"
                  type="email"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.email"
                  :rules="[rules.required, rules.email]"
                />
              </v-col>
              <v-col cols="8">
                <AppPhoneNumberInput
                  v-model="agency.phone"
                  @update="onUpdatePhone"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  hide-details
                  label="Ext"
                  dense
                  rounded
                  filled
                  v-model="agency.phone_ext"
                  background-color="white"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Fax"
                  type="text"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.fax"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Contact Person"
                  type="text"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.contact_person"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Contact Title"
                  type="text"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.contact_title"
                />
              </v-col>
              <v-col cols="12">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-textarea
                      hide-details
                      label="Additional Notification Recipient(s)"
                      type="text"
                      dense
                      rounded
                      filled
                      rows="3"
                      auto-grow
                      background-color="white"
                      v-model="agency.additional_notification_recipients"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <span
                    >Add a new email address per line.<br />
                    Emails entered here will be sent notifications for need
                    response and event resvps.<br />
                    This field does not appear publicly.</span
                  >
                </v-tooltip>
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="updateAgency"
                >
                  Update Information
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <span class="subtitle mb-6 ma-3">Links</span>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Official Website"
                  type="website"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.website"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Facebook"
                  type="facebook"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.facebook"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Twitter"
                  type="twitter"
                  dense
                  rounded
                  filled
                  background-color="white"
                  v-model="agency.twitter"
                />
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="updateAgency"
                >
                  Update Information
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <span class="subtitle mb-6 ma-3">Photos</span>
              <v-col cols="12">
                <agency-photo-viewer
                  :editable="true"
                  :agency_id="$route.params.id"
                  ref="viewer"
                />
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="onAddPhoto"
                  v-text="'Add Photo'"
                />
              </v-col>
            </v-row>
            <v-row>
              <span class="subtitle mb-6 ma-3">Change Password</span>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="New Password"
                  type="password"
                  dense
                  rounded
                  filled
                  background-color="white"
                  :rules="[rules.required, rules.min, rules.passwordMatch]"
                  v-model="newPassword"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  hide-details
                  label="Confirm Password"
                  type="password"
                  dense
                  rounded
                  filled
                  background-color="white"
                  :rules="[rules.required, rules.min, rules.passwordMatch]"
                  v-model="confirmPassword"
                />
              </v-col>
              <v-col cols="12" style="display:flex; justify-content: flex-end">
                <v-btn
                  rounded
                  color="#472583"
                  class="mt-5"
                  dark
                  @click="updatePasswordButtonClicked"
                  v-text="'Update Password'"
                />
              </v-col>
            </v-row>
            <div class="mt-5" style="display:flex; flex-direction: column;">
              <span class="subtitle mb-6  ma-3">Deactivate Account</span>
              <span class="ml-5">
                Click the button below to deactivate your account.
                <b>This cannot be undone.</b>
              </span>
              <v-btn
                rounded
                color="#472583"
                class="mt-5 ml-3"
                dark
                @click="deleteAccount"
              >
                Deactivate Account
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-overlay :value="loading">
        <v-container fluid fill-height>
          <v-layout justify-center align-center>
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </v-container>
    <v-container v-else>Permission Denied</v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyAvatar from "../../components/agency/AgencyAvatar.vue";
import AgencyPhotoViewer from "../../components/agency/AgencyPhotoViewer.vue";
import ConfirmDeleteDialog from "../../components/common/ConfirmDeleteDialog.vue";
import AppPhoneNumberInput from "../../components/common/AppPhoneNumberInput.vue";
export default {
  components: {
    AgencyAvatar,
    AgencyPhotoViewer,
    ConfirmDeleteDialog,
    AppPhoneNumberInput,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 6) || "Min 6 characters",
        passwordMatch: () =>
          this.newPassword != this.confirmPassword
            ? `Password doesn't match`
            : "",
      },
      stateOptions: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
        "AS",
        "DC",
        "FM",
        "GU",
        "MH",
        "MP",
        "PW",
        "PR",
        "VI",
      ],
      loading: false,
      selectedImage: null,
      errorMessage: null,
      snackbar: false,
      agency: null,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      deleteDialog: false,
    };
  },
  methods: {
    ...mapActions({
      getAgency: "agency/getAgency",
      editAgency: "agency/editAgency",
      deleteAgency: "agency/deleteAgency",
      updatePassword: "agency/updatePassword",
      getCauseIcons: "icon/getCauseIcons",
    }),
    updateAgency() {
      var formData = new FormData();
      this.loading = true;
      this.agency.causesStr = this.agency.causes.join();

      Object.keys(this.agency).map((key) => {
        if (this.agency[key] != undefined)
          formData.append(key, this.agency[key]);
      });

      let phone = this.agency.phone;
      if (
        this.phonePayload.isValid &&
        this.phonePayload.countryCallingCode != "1"
      ) {
        phone = `+${this.phonePayload.countryCallingCode} ${phone}`;
        formData.append("phone", phone);
      }

      if (this.selectedImage) {
        formData.append("image", this.selectedImage);
      }
      this.editAgency(formData)
        .then(() => (this.loading = false))
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    loadData() {
      this.loading = true;
      this.getAgency(this.$route.params.id)
        .then((data) => {
          this.loading = false;
          this.agency = data;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.tokenDialog = true;
          } else {
            console.log(error);
          }
        });
    },
    onImageSelected(image) {
      this.selectedImage = image;
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onBack() {
      this.$router.back();
    },
    onAddPhoto() {
      this.$refs.viewer.onAddPhoto();
    },
    updatePasswordButtonClicked() {
      if (!this.newPassword || !this.confirmPassword) {
        this.showErrorMessage("Please fill all the required field");
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        this.showErrorMessage("Password does not match");
        return;
      }
      if (this.newPassword.length < 6) {
        this.showErrorMessage("Please should be at least 6 letters");
        return;
      }
      this.loading = true;
      this.updatePassword({
        _id: this.agency._id,
        new_password: this.newPassword,
      })
        .then(() => {
          this.loading = false;
          this.showErrorMessage("Password updated successfully!");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    deleteAccount() {
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteAgency({ _id: this.agency._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    onUpdatePhone(payload) {
      this.phonePayload = payload;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      causes: "icon/causes",
    }),
  },
  watch: {
    "$route.params.id": function() {
      this.loadData();
    },
  },
  created() {
    this.loadData();
    this.getCauseIcons()
      .then(() => {
        console.log(this.causes);
      })
      .catch((error) => console.log(error));
  },
};
</script>
<style scoped>
.page-content {
  overflow: hidden;
  height: 100%;
  padding-right: 16px;
}
.page-content:hover {
  overflow-y: scroll;
  padding-right: 8px;
}
.agency-title {
  color: #472583;
  font-size: 30px;
  font-weight: bold;
  width: 250px;
  height: 40px;
  min-height: 40px;
  display: flex;
  padding-top: 10px;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 4px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #febb349a;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.category-title {
  font-size: 32px;
  color: #472583;
  font-weight: 500;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}
.subtitle {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
</style>
