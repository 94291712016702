<template>
  <v-container class="pt-0">
    <div style="display: flex; flex-drection: row; align-items: center;">
      <span class="agency-title">Products</span>
      <div class="title-line ml-3" />
    </div>
    <v-text-field
      hide-details
      label="Search"
      solo
      dense
      rounded
      outlined
      append-icon="mdi-magnify"
    />
    <v-row class="mt-3">
      <v-col cols="12" md="6" v-for="(product, key) in products" :key="key">
        <v-card>
          <div style="display: flex; flex-direction: row;">
            <v-img
              src="@/assets/samples/product.png"
              width="160"
              height="160"
              max-width="160"
              max-height="160"
              contain
              class="ma-3"
            />
            <div style="display: flex; flex-direction: column; flex-grow: 1;">
              <div
                class="mt-3"
                style="display:flex; justify-content: space-between;"
              >
                <span class="product-title">
                  {{ product }}
                </span>
                <v-btn class="mr-2" icon
                  ><v-icon color="#472583">mdi-pencil</v-icon></v-btn
                >
              </div>
              <v-rating
                :value="3.7"
                half-increments
                size="30"
                readonly
                background-color="#fdbc3a"
                color="#fdbc3a"
              />
              <span class="brand-title mt-2">
                Nick Spots
              </span>
              <span class="price-text mt-2">
                $980
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      products: [
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
        "Chrono Caviar",
      ],
    };
  },
};
</script>
<style scoped>
.agency-title {
  color: #472583;
  font-size: 50px;
  font-weight: bold;
}
.title-line {
  height: 2px;
  width: 100%;
  background-color: #472583;
}
.agency-scroll-section {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

::-webkit-scrollbar {
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #feb934;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f5bd4d;
}

.product-title {
  font-size: 28px;
  color: #472583;
  font-weight: 500;
}
.brand-title {
  font-size: 18px;
  color: #472583;
}
.price-text {
  font-size: 18px;
  color: #472583;
  font-weight: 500;
}
</style>
